import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { TelegramLoginService } from './telegram-login.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'hb-telegram-login-widget',
  template: ` <div #script [style.display]="'none'">
    <ng-content></ng-content>
  </div>`,
  styles: [],
  standalone: true
})
export class TelegramLoginWidgetComponent implements AfterViewInit {
  @Input() public returnUrl!: string;
  @Output() public loginSuccess: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('script', { static: true }) public script!: ElementRef;
  constructor(private telegramLoginService: TelegramLoginService, @Inject(PLATFORM_ID) private platformId: string) { }

  // <script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-login="HobbestBot" data-size="large" data-onauth="onTelegramAuth(user)" data-request-access="write"></script>
  // <script type="text/javascript">
  //   function onTelegramAuth(user) {
  //     alert('Logged in as ' + user.first_name + ' ' + user.last_name + ' (' + user.id + (user.username ? ', @' + user.username : '') + ')');
  //   }
  // </script>
  public convertToScript(): void {
    const element = this.script.nativeElement as HTMLScriptElement;
    if (isPlatformBrowser(this.platformId)) {
      const script = document.createElement('script');
      script.src = 'https://telegram.org/js/telegram-widget.js?22';
      script.setAttribute('data-telegram-login', environment.botName);
      script.setAttribute('data-size', 'large');
      // Callback function in global scope
      script.setAttribute('data-onauth', 'loginViaTelegram(user)');
      script.setAttribute('data-request-access', 'write');
      element.parentElement?.replaceChild(script, element);
    }
  }

  public ngAfterViewInit(): void {
    this.telegramLoginService.init(this.returnUrl, () => this.loginSuccess.emit());
    this.convertToScript();
  }
}
