import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { AuthService } from '../../auth.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TelegramLoginService {
  private returnUrl?: string;
  private loginCallback?: () => void;

  constructor(private zone: NgZone, private authService: AuthService, @Inject(PLATFORM_ID) private platformId: string) { }

  public init(returnUrl?: string, loginCallback?: () => void): void {
    this.returnUrl = returnUrl;
    this.loginCallback = loginCallback;
    if (isPlatformBrowser(this.platformId)) {
      (window as Record<string, any>).loginViaTelegram = (loginData: any) => this.loginViaTelegram(loginData);
    }
  }

  private loginViaTelegram(loginData: any): void {
    // If the login should trigger view changes, run it within the NgZone.
    this.zone.run(() => {
      this.authService.login(loginData, this.returnUrl);
      if (this.loginCallback) {
        this.loginCallback();
      }
    });
  }
}
